import React from "react";

// plugin imports
import { Trans, useTranslation } from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";
import PhoneInput, {
	isPossiblePhoneNumber,
	parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ReCAPTCHA from "react-google-recaptcha";

// style imports
import "./bookingForm.scss";

// component imports
import { makeReservation } from "../../utils/coverAPI";

// svg imports
import Separador from "../../svg/lineaSeparador.svg";

const FORM_ENDPOINT = "";

export default function BookingForm({
	restaurantData,
	restaurantForm,
	personDetails,
	setPersonDetails,
	setReservResponse,
}) {
	const { t } = useTranslation("bookingAPI");
	const { language } = useI18next();

	// Covermanager policies link construction
	const coverLanguage = {
		es: "spanish",
		en: "english",
	};
	const coverLegalRestURL =
		"https://www.covermanager.com/legal/load_legal_dynamic/" +
		(restaurantData?.frontmatter?.coverManagerId !== undefined
			? restaurantData?.frontmatter?.coverManagerId
			: "restaurante-begin-cortes") +
		"/" +
		(coverLanguage[language] || "spanish");
	const tratamientoDatosLink = coverLegalRestURL + "/personal_info/0/0";
	const condicionesUsoLink = coverLegalRestURL + "/use_conditions/0/0";

	const [loading, setLoading] = React.useState(false);
	const [errorFetchingData, setErrorFetchingData] = React.useState(false);
	const [captchaError, setCaptchaError] = React.useState(false);

	const [captcha, setCaptcha] = React.useState(null);
	const recaptchaRef = React.useRef(null);
	// console.log(captcha);

	// Creating a custom Commentary formatting to pass alternative data such as special needs
	const [customCommentary, setCustomCommentary] = React.useState("");
	React.useEffect(() => {
		const specialNeedsValues = {
			baby_stroller: "Carrito de bebé",
			baby_high_chair: "Trona",
			// "baby_stroller_+_baby_high_chair": "Trona y Carrito de bebé",
			wheelchair: "Silla de ruedas",
			// nothing: "Nada", // Lo comentamos para que si está marcada no aparezca ni la pregunta en el comentario
		};

		const specialNeedsFormatted = [];
		personDetails.special_need.map((need) => {
			// console.log(specialNeedsValues[need]);
			if (specialNeedsValues[need])
				specialNeedsFormatted.push(specialNeedsValues[need]);
			return null;
		});
		// console.log(specialNeedsFormatted);
		setCustomCommentary(
			"\n" +
				(personDetails.commentary
					? "Comentario: " + personDetails.commentary + "\n\n"
					: "") +
				(specialNeedsFormatted.length > 0
					? "P. Necesidad especial (" +
					  specialNeedsFormatted.length +
					  "): " +
					  specialNeedsFormatted.join(", ")
					: "")
		);
	}, [personDetails.commentary, personDetails.special_need]);
	// console.log(personDetails.special_need);

	const [phone, setPhone] = React.useState("");
	const [validPhone, setValidPhone] = React.useState(true);
	// console.log(validPhone);

	const handleSubmit = async (e) => {
		e.preventDefault();
		var token = captcha;

		if (!token) {
			token = await recaptchaRef.current.executeAsync();
		}

		if (token) {
			// console.log("Captcha successful");
			const phoneValidation = isPossiblePhoneNumber(phone);
			setValidPhone(phoneValidation);
			setErrorFetchingData(false);
			if (phoneValidation) {
				setLoading(true);
				// console.log(restaurantForm, personDetails);
				makeReservation(
					restaurantForm,
					personDetails,
					customCommentary,
					setReservResponse,
					setErrorFetchingData
				);
			}
		}
	};

	React.useEffect(() => {
		if (errorFetchingData === true) {
			setLoading(false);
		}
	}, [errorFetchingData]);

	const handleChange = (e) => {
		// console.log(e.target.checked);
		if (e.target.type === "checkbox") {
			return setPersonDetails((prevState) => {
				const prevStateValue = prevState[e.target.name];
				// console.log(prevStateValue);
				// If it is an array, add or remove from it
				if (Array.isArray(prevStateValue)) {
					const newStateValue = e.target.checked
						? [...prevState[e.target.name], e.target.value]
						: prevState[e.target.name].filter((el) => el !== e.target.value);
					return {
						...prevState,
						[e.target.name]: newStateValue,
					};
				}
				// If it is a boolean, just toggle it
				return {
					...prevState,
					[e.target.name]: e.target.checked ? 1 : 0,
				};
			});
		}
		return setPersonDetails((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};

	// handle Change of phone component
	React.useEffect(() => {
		if (phone && isPossiblePhoneNumber(phone)) {
			const parsedPhone = parsePhoneNumber(phone) || undefined;
			setPersonDetails((prevState) => {
				return {
					...prevState,
					int_call_code: parsedPhone?.countryCallingCode || "",
					phone: parsedPhone?.nationalNumber || "",
				};
			});
		}
	}, [phone, setPersonDetails]);

	// update coverLang on component mount
	React.useEffect(() => {
		setPersonDetails((prevState) => {
			return {
				...prevState,
				language: coverLanguage[language],
			};
		});
	}, []); // eslint-disable-line

	return (
		<div className="lastStepContainer">
			<div>
				<div className="datosReserva">
					<h1>Begin {restaurantData?.frontmatter?.title}</h1>
					<p>
						<span>{restaurantForm?.date}</span> |{" "}
						<span>
							{restaurantForm?.people} <Trans ns="bookingAPI">personas</Trans>
						</span>{" "}
						| <span>{restaurantForm?.time}</span> |{" "}
						<span>{restaurantForm?.zone?.name}</span>
						{/*  |{" "}
						<span>Begin {restaurantData?.frontmatter?.title}</span> */}
					</p>
				</div>

				<div className="bookingTimeDisclaimer onlyDesktop">
					<br />
					<p>
						<Trans ns="bookingAPI">
							Tendrás 15 minutos de cortesía desde la hora del inicio de la
							reserva.
						</Trans>
					</p>
					<p>
						<Trans ns="bookingAPI">
							Tu experiencia con esta reserva tendrá una duración de 1 hora y 30
							minutos.
						</Trans>
					</p>
				</div>
			</div>
			<div className="bookingForm">
				<form
					action={FORM_ENDPOINT}
					onSubmit={handleSubmit}
					method="POST"
					target="_blank"
				>
					<ReCAPTCHA
						ref={recaptchaRef}
						size="invisible"
						onChange={(token) => {
							setCaptcha(token);
							setCaptchaError(!token);
						}}
						sitekey={process.env.GATSBY_CM_RECAPTCHA_SITEKEY}
						hl={language}
						// sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // Test Key
					/>
					<div>
						<label htmlFor="first_name">
							<p>
								<Trans ns="bookingAPI">Nombre</Trans>:
							</p>
						</label>
						<input
							type="text"
							placeholder={t("Nombre")}
							id="first_name"
							name="first_name"
							title=" "
							required
							value={personDetails.first_name || ""}
							onChange={handleChange}
						/>
					</div>

					<div>
						<label htmlFor="last_name">
							<p>
								<Trans ns="bookingAPI">Apellidos</Trans>:
							</p>
						</label>
						<input
							type="text"
							placeholder={t("Apellidos")}
							id="last_name"
							name="last_name"
							title=" "
							required
							value={personDetails.last_name || ""}
							onChange={handleChange}
						/>
					</div>

					<div>
						<label htmlFor="email">
							<p>
								<Trans ns="bookingAPI">Email</Trans>:
							</p>
						</label>
						<input
							type="email"
							placeholder={t("Email")}
							id="email"
							name="email"
							title=" "
							required
							value={personDetails.email || ""}
							onChange={handleChange}
						/>
					</div>

					<div>
						<p className="label">
							<Trans ns="bookingAPI">Teléfono</Trans>:
						</p>
						<PhoneInput
							international
							defaultCountry="ES"
							value={
								phone || "+" + personDetails.int_call_code + personDetails.phone
							}
							name="phone"
							onChange={setPhone}
							withCountryCallingCode={true}
							placeholder={t("Teléfono")}
							required
						/>
						{!validPhone && (
							<span className="mobileError">
								<Trans ns="bookingAPI">Revisa tu número de móvil</Trans>
							</span>
						)}
					</div>

					{/* <div>
						<label htmlFor="commentary">
							<p>
								<Trans ns="bookingAPI">Comentario</Trans>:
							</p>
						</label>
						<textarea
							type="commentary"
							placeholder={t("Comentario")}
							maxLength="280"
							id="commentary"
							name="commentary"
							title=" "
						/>
					</div> */}

					<div className="checkboxQuestion">
						<p>
							<Trans ns="bookingAPI">
								¿Tienes alguna necesidad que tengamos que tener en cuenta?
								(opcional)
							</Trans>
							:
						</p>
						<div>
							<input
								type="checkbox"
								name="special_need"
								value="baby_stroller"
								id="baby_stroller"
								checked={personDetails.special_need.includes("baby_stroller")}
								onChange={handleChange}
							/>
							<label htmlFor="baby_stroller" aria-label={t("Carrito de bebé")}>
								<Trans ns="bookingAPI">Carrito de bebé</Trans>
							</label>
						</div>
						<div>
							<input
								type="checkbox"
								name="special_need"
								value="baby_high_chair"
								id="baby_high_chair"
								checked={personDetails.special_need.includes("baby_high_chair")}
								onChange={handleChange}
							/>
							<label htmlFor="baby_high_chair" aria-label={t("Trona")}>
								<Trans ns="bookingAPI">Trona</Trans>
							</label>
						</div>
						{/* <div>
							<input
								type="checkbox"
								name="special_need"
								value="baby_stroller_+_baby_high_chair"
								id="baby_stroller_+_baby_high_chair"
							/>
							<label
								htmlFor="baby_stroller_+_baby_high_chair"
								aria-label={t("Carrito de bebé") + t("Trona")}
							>
								<Trans ns="bookingAPI">Carrito de bebé</Trans> +{" "}
								<Trans ns="bookingAPI">Trona</Trans>
							</label>
						</div> */}
						<div>
							<input
								type="checkbox"
								name="special_need"
								value="wheelchair"
								id="wheelchair"
								checked={personDetails.special_need.includes("wheelchair")}
								onChange={handleChange}
							/>
							<label htmlFor="wheelchair" aria-label={t("Silla de ruedas")}>
								<Trans ns="bookingAPI">Silla de ruedas</Trans>
							</label>
						</div>
						{/* <div>
							<input
								type="checkbox"
								name="special_need"
								value="nothing"
								id="nothing"
							/>
							<label htmlFor="nothing" aria-label={t("Silla de ruedas")}>
								<Trans ns="bookingAPI">Nada</Trans>
							</label>
						</div> */}
					</div>

					<div className="dataProtection">
						<div className="dataCheck">
							<input
								type="checkbox"
								name="trat_datos"
								id="trat_datos"
								onChange={handleChange}
								required
							/>
							<label
								htmlFor="trat_datos"
								aria-label={t("Consiento el tratamiento de datos personales")}
							>
								<Trans ns="bookingAPI">Consiento el</Trans>{" "}
								<a
									href={tratamientoDatosLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Trans ns="bookingAPI">tratamiento de datos personales</Trans>
								</a>
							</label>
						</div>
						<div className="dataCheck">
							<input
								type="checkbox"
								name="com_comercial"
								id="com_comercial"
								onChange={handleChange}
							/>
							<label
								htmlFor="com_comercial"
								aria-label={t(
									"Consiento la recepción de comunicaciones del restaurante por e-mail y/o SMS con fines comerciales"
								)}
							>
								<Trans ns="bookingAPI">
									Consiento la recepción de comunicaciones del restaurante por
									e-mail y/o SMS con fines comerciales
								</Trans>
							</label>
						</div>
					</div>

					<div className="formSubmit">
						{loading ? (
							<p>
								<Trans ns="bookingAPI">Enviando</Trans>...
							</p>
						) : (
							<>
								<button
									type="submit"
									aria-label={t("Enviar")}
									className="underline"
								>
									<span>
										<Trans ns="bookingAPI">Reservar</Trans>
									</span>
								</button>
								<span>
									<Trans ns="bookingAPI">Acepto las</Trans>{" "}
									<a
										href={condicionesUsoLink}
										target="_blank"
										rel="noopener noreferrer"
									>
										<Trans ns="bookingAPI">
											condiciones de uso, política de privacidad y aviso legal
										</Trans>
									</a>
								</span>
								<div className="bookingTimeDisclaimer onlyMobile">
									<br />
									<p>
										<Trans ns="bookingAPI">
											Tendrás 15 minutos de cortesía desde la hora del inicio de
											la reserva.
										</Trans>
									</p>
									<p>
										<Trans ns="bookingAPI">
											Tu experiencia con esta reserva tendrá una duración de 1
											hora y 30 minutos.
										</Trans>
									</p>
								</div>
							</>
						)}
						{(errorFetchingData || captchaError) && (
							<p>
								<Trans ns="bookingAPI">
									Se ha producido un error al realizar tu reserva.
								</Trans>
								<br />
								<Trans ns="bookingAPI">
									Por favor inténtalo de nuevo en unos instantes. Si el problema
									persiste, contacta con nosotros.
								</Trans>
							</p>
						)}
					</div>

					<Separador />

					<div className="dataProtection">
						<p>
							<Trans>
								Sitio protegido por reCAPTCHA y se aplican la{" "}
								<a href="https://policies.google.com/privacy">
									Política de Privacidad
								</a>{" "}
								y las{" "}
								<a href="https://policies.google.com/terms">
									Condiciones de Servicio
								</a>{" "}
								de Google.
							</Trans>
						</p>
						<p>
							<Trans ns="dataProtection">
								Información básica sobre protección de datos
							</Trans>
							:
						</p>
						<p>
							<Trans ns="dataProtection">
								En cumplimiento del Reglamento General de Protección de Datos de
								Carácter Personal, se informa al interesado de lo siguiente
							</Trans>
							:
						</p>
						<table>
							<tbody>
								<tr>
									<td>
										<Trans ns="dataProtection">Responsable</Trans>:
									</td>
									<td>BEGIN RESTAURANTES, S.L.</td>
								</tr>
								<tr>
									<td>
										<Trans ns="dataProtection">Domicilio</Trans>:
									</td>
									<td>
										Av. Cortes Valencianas, 50, CP 46015 Valencia (Valencia)
									</td>
								</tr>
								<tr>
									<td>
										<Trans ns="dataProtection">Finalidad</Trans>:
									</td>
									<td>
										<Trans ns="dataProtection">
											La prestación de servicios y la gestión de la relación
											comercial. Gestión de duplicidades con otros restaurantes
										</Trans>
										.
										<a
											href={tratamientoDatosLink}
											target="_blank"
											rel="noopener noreferrer"
										>
											{"("}
											<Trans ns="bookingAPI">Ver más</Trans>
											{")"}
										</a>
									</td>
								</tr>
								<tr>
									<td>
										<Trans ns="dataProtection">Legitimacion</Trans>
									</td>
									<td>
										<Trans ns="dataProtection">
											Consentimiento del interesado
										</Trans>
										<a
											href={tratamientoDatosLink}
											target="_blank"
											rel="noopener noreferrer"
										>
											{"("}
											<Trans ns="bookingAPI">Ver más</Trans>
											{")"}
										</a>
										.
										<br />
										<Trans ns="dataProtection">
											Ejecución de un contrato en el que el interesado es parte.
										</Trans>
									</td>
								</tr>
								<tr>
									<td>
										<Trans ns="dataProtection">Destinatarios</Trans>:
									</td>
									<td>
										<Trans ns="dataProtection">
											Comunicación a restaurantes afectados en caso de
											duplicidad de reservas; a CoverManager para la gestión de
											la reserva y por requerimiento legal. Se podrán realizar
											cesiones de datos para fines estadísticos
										</Trans>
										.{" "}
										<a
											href={tratamientoDatosLink}
											target="_blank"
											rel="noopener noreferrer"
										>
											{"("}
											<Trans ns="bookingAPI">Ver más</Trans>
											{")"}
										</a>
									</td>
								</tr>
								<tr>
									<td>
										<Trans ns="dataProtection">Derechos</Trans>:
									</td>
									<td>
										<Trans ns="dataProtection">
											Acceso, Rectificación, Supresión, Limitación del
											Tratamiento, Portabilidad y Oposición
										</Trans>
										.{" "}
										<a
											href={tratamientoDatosLink}
											target="_blank"
											rel="noopener noreferrer"
										>
											{"("}
											<Trans ns="bookingAPI">Ver más</Trans>
											{")"}
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</form>
			</div>
		</div>
	);
}
