import "../../styles/section.css";
import "./reserva.css";

import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";
// import { Link } from "gatsby-plugin-react-i18next";
// import IframeResizer from "iframe-resizer-react";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import LocalesGrid from "../../components/localesGrid/localesGrid";
// import CodeProtection from "../../components/reserva/CodeProtection"

import FlorituraHoja from "../../svg/florituras/florituraHoja.svg";
import Subrayado5 from "../../svg/subrayados/subrayado05.svg";
import Booking from "../../components/reserva/booking";

export default function IndexPage({ location, data }) {
	// console.log(data);
	// const { language, t } = useTranslation();
	const { t } = useTranslation();

	const [restaurant, setRestaurant] = React.useState(undefined);
	React.useEffect(() => {
		const params = new URLSearchParams(location.search);
		if (params?.get("pre")) {
			setRestaurant(params?.get("pre"));
		}
	}, [location]);

	return (
		<Layout
			footerText={t("Pequeños momentos como este")}
			className="invertColors"
		>
			<Seo title={t("Reserva")} />

			<div id="reserva" className="section invertColors">
				<div className="tituloReserva">
					<h1 className="lowercase">
						<Trans i18nKey="titleReserva">
							¿Dónde quieres vivir la <i>experiencia</i>?
						</Trans>
					</h1>
					<h2 className="lowercase">
						<Trans>
							Nos encanta que quieras venir a vivir la experiencia a nuestros
							locales. Selecciona un local para ver todas las opciones que
							tenemos para ti.
						</Trans>
					</h2>
				</div>

				<Booking
					restaurantsData={data.restaurants}
					presetRestaurant={restaurant}
				/>

				{/* <div id="coverManager" className="abouttext reservatext reservaForm">
					<ReservaPlaceholder />
					<>
						<script
							type="text/javascript"
							src="https://www.covermanager.com/js/iframeResizer.min.js"
						></script>
						{language === "en" ? (
							<IframeResizer
								id="restaurante-voltereta-general"
								title="Reservas"
								src="https://www.covermanager.com/reserve/module_pre_filter/begin/english"
								frameBorder="0"
								height="300"
								width="100%"
								heightCalculationMethod="max"
								scrolling="true"
							/>
						) : (
							<IframeResizer
								id="restaurante-voltereta-general"
								title="Reservas"
								src="https://www.covermanager.com/reserve/module_pre_filter/begin/spanish"
								frameBorder="0"
								height="300"
								width="100%"
								heightCalculationMethod="max"
								scrolling="true"
							/>
						)}
					</>

					<div className="pieReserva">
						<p>
							<Trans>
								Las reservas están disponibles hasta un máximo de 30 días de
								antelación.
							</Trans>
						</p>
						<p>
							<Trans>
								El número máximo de personas varía según el local. Si necesitas
								espacio extra contáctanos <Link to="/contacto">aquí</Link> e
								intentaremos hacer lo posible para ayudarte.
							</Trans>
						</p>
					</div>
				</div> */}

				<div className="tituloReserva localesGridTitulo">
					<h3>
						<Trans>
							¿Quieres descubrir más sobre cada uno de los espacios?
						</Trans>
					</h3>
				</div>
				<LocalesGrid restaurants={data.restaurants} />

				<FlorituraHoja className="footerFloritura" />

				<div className="prefooterNote">
					<h2>
						<Trans>
							También tenemos la opción de{" "}
							<a href="https://linktr.ee/begintakeaway">
								recoger en local
								<Subrayado5 />
							</a>{" "}
							o de{" "}
							<a href="https://linktr.ee/beginrestaurante">
								envío a domicilio
								<Subrayado5 />
							</a>{" "}
							,
						</Trans>
					</h2>
					<h2>
						<Trans>¡Tú decides cómo vivir la experiencia!</Trans>
					</h2>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}

		restaurants: allMarkdownRemark(
			filter: { fields: { language: { eq: $language } } }
			sort: { order: ASC, fields: fileAbsolutePath }
		) {
			nodes {
				fields {
					language
				}
				frontmatter {
					id
					coverManagerId
					simpleName
					address1
					addressCity
					addressLink
					addressPostal
					deliveryLinks {
						name
						url
					}
					hours {
						brunch
						dinner
						lunch
					}
					maxPeople
					menuBrunch
					menuDia
					menuNavidad
					menuGrupo
					menuNoche
					takeAwayLink
					subtitle
					title
					images {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`;
