import React from "react";

// plugins imports
import moment from "moment/moment";
import { Trans, useTranslation } from "react-i18next";
import { navigate } from "gatsby";
import { CSSTransition, SwitchTransition } from "react-transition-group";

// styles imports
import "./booking.scss";

// utils imports
import { fetchAvailability } from "../../utils/coverAPI";
// import { responseExample } from "../../utils/coverTestObject";

// components imports
import BookingCalendar from "./bookingCalendar";
import BookingHourZoneSelector from "./bookingHourZoneSelector";
import BookingForm from "./bookingForm";
import { Link } from "gatsby-plugin-react-i18next";

const CalendarLegend = () => (
	<div className="calendarLegend">
		<p className="available">
			<Trans ns="bookingAPI">Hay disponibilidad en el local seleccionado</Trans>
		</p>
		<p className="semiavailable">
			<Trans ns="bookingAPI">Hay disponibilidad en otros locales</Trans>
		</p>
		<p className="unavailable">
			<Trans ns="bookingAPI">No hay disponibilidad</Trans>
		</p>
	</div>
);

export default function Booking({
	presetRestaurant = undefined,
	restaurantsData,
}) {
	// console.log(restaurantsData);
	const { t } = useTranslation("bookingAPI");
	const ref = React.useRef(null);

	const [screen, setScreen] = React.useState(0);

	const initialForm_initialState = {
		restaurant: undefined,
		people: 2,
		turn: undefined,
	};
	const [initialForm, setInitialForm] = React.useState(
		initialForm_initialState
	);
	// console.log(initialForm);

	const restaurantForm_initialState = {
		people: undefined,
		date: undefined,
		time: undefined,
		zone: {
			id: undefined,
			name: undefined,
		},
		restaurant: undefined,
	};
	const [restaurantForm, setRestaurantForm] = React.useState(
		restaurantForm_initialState
	);
	// console.log(restaurantForm);

	React.useEffect(() => {
		const presetRestaurantCoverId =
			restaurantsData?.nodes?.find((restaurant) => {
				return restaurant.frontmatter.id === presetRestaurant;
			})?.frontmatter?.coverManagerId || undefined;

		setInitialForm((prevState) => ({
			...prevState,
			restaurant: presetRestaurantCoverId,
		}));
	}, [presetRestaurant, restaurantsData]);

	const personDetails_initialState = {
		first_name: "",
		last_name: "",
		email: "",
		int_call_code: "34",
		phone: "",
		commentary: "",
		special_need: [],
		trat_datos: 0,
		com_comercial: 0,
		language: "es",
	};
	const [personDetails, setPersonDetails] = React.useState(
		personDetails_initialState
	);
	// console.log(personDetails);

	const [availableDates, setAvailableDates] = React.useState(() => new Set());
	const [semiAvailableDates, setSemiAvailableDates] = React.useState(
		() => new Set()
	);

	// const restaurantsAvailabilityDev = responseExample; // For dev purposes, use restaurantesAvailability when ready
	const [restaurantsAvailability, setRestaurantsAvailability] =
		React.useState(undefined);
	// console.log("restAvailability: ", restaurantsAvailability);

	const [reservResponse, setReservResponse] = React.useState();
	// console.log(reservResponse);

	const [errorFetchingData, setErrorFetchingData] = React.useState(false);
	const [localsToFetch, setLocalsToFetch] = React.useState(0);
	const [dataFetched, setDataFetched] = React.useState(false);

	const [selectedDay, setSelectedDay] = React.useState(undefined);
	// console.log(selectedDay);

	const turns = {
		brunch: {
			from: "09:00",
			to: "12:00",
		},
		lunch: {
			from: "12:30",
			to: "18:45",
		},
		dinner: {
			from: "19:00",
			to: "23:45",
		},
	};

	const minDate = moment().toDate();
	const maxDate = moment().add(45, "days").toDate();

	React.useEffect(() => {
		// console.log("This should only run once");
		for (const restaurant of restaurantsData.nodes) {
			if (restaurant.frontmatter.coverManagerId) {
				setLocalsToFetch((prevState) => prevState + 1);
				fetchAvailability(
					restaurant.frontmatter.coverManagerId,
					moment(minDate).format("YYYY-MM-DD"),
					moment(maxDate).format("YYYY-MM-DD"),
					setRestaurantsAvailability,
					setErrorFetchingData
				);
			}
		}
	}, []); // eslint-disable-line

	React.useEffect(() => {
		// console.log(reservResponse);
		if (reservResponse?.resp === 1) {
			setScreen(2);
		}
	}, [reservResponse]);

	// set dataFetch success or error upon fulfilling fetch requests
	React.useEffect(() => {
		if (
			typeof restaurantsAvailability === "object" &&
			Object.keys(restaurantsAvailability)?.length === localsToFetch &&
			!errorFetchingData
		) {
			setDataFetched(true);
		}
	}, [
		restaurantsAvailability,
		errorFetchingData,
		restaurantsData.nodes.length,
		localsToFetch,
	]);

	const [peopleDisclaimer, setPeopleDisclaimer] = React.useState(null);
	const maxPeople = 10;
	const minPeople = 1;
	const onPeopleChange = (input) => {
		var inputPeople = parseInt(input);
		if (inputPeople > maxPeople) {
			setPeopleDisclaimer("max");
			inputPeople = maxPeople;
		} else if (inputPeople < minPeople) {
			setPeopleDisclaimer("min");
			inputPeople = minPeople;
		} else {
			setPeopleDisclaimer(null);
		}

		return setInitialForm((prevState) => {
			return { ...prevState, people: inputPeople };
		});
	};

	const handleChange = (e) => {
		setSelectedDay(null);
		if (e.target.name === "people") {
			onPeopleChange(e.target.value);
		}
		return setInitialForm((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};

	// Show a fake loading effect to communicate that new input has been loaded to user
	const [fakeLoader, setFakeLoader] = React.useState(false);
	React.useEffect(() => {
		setFakeLoader(true);
		setTimeout(() => setFakeLoader(false), 500);
	}, [initialForm]);

	return (
		<div className="bookingAPI" id="reservaForm">
			<SwitchTransition mode="out-in">
				<CSSTransition
					key={screen}
					timeout={400}
					classNames="fade"
					onExit={() => {
						navigate("#reservaForm");
					}}
					nodeRef={ref}
				>
					<div ref={ref}>
						{
							{
								0: (
									<div className="firstStep">
										<div>
											<form
												onChange={handleChange}
												target="_blank"
												className="initialForm"
											>
												<p>
													<select
														id="frestaurant"
														name="restaurant"
														placeholder={t("Restaurante")}
														required
														className="p1 formel"
														value={initialForm.restaurant || ""}
													>
														<option value="" disabled>
															{t("Restaurante")}
														</option>
														<option value="all">
															{t("Todos los restaurantes")}
														</option>
														{restaurantsData.nodes.map((restaurant) => {
															if (restaurant.frontmatter.coverManagerId) {
																return (
																	<option
																		value={
																			restaurant.frontmatter.coverManagerId
																		}
																		key={restaurant.frontmatter.id}
																		defaultValue={
																			initialForm.restaurant ===
																			restaurant.frontmatter
																		}
																	>
																		Begin {restaurant.frontmatter.simpleName}
																	</option>
																);
															}
															return null;
														})}
													</select>
												</p>
												<div className="counterInput">
													<label
														htmlFor="people"
														id="people"
														name="people"
														placeholder={t("Personas")}
														required
													>
														<Trans>Personas</Trans>
													</label>
													<div>
														<button
															onClick={(e) => {
																e.preventDefault();
																onPeopleChange(initialForm.people - 1);
															}}
															className={`${
																initialForm.people === minPeople
																	? "disabled"
																	: ""
															}`}
														>
															-
														</button>
														<input
															type="text"
															value={initialForm.people}
															id="people"
															name="people"
															title=""
															onChange={handleChange}
														/>
														<button
															onClick={(e) => {
																e.preventDefault();
																onPeopleChange(initialForm.people + 1);
															}}
															className={`${
																initialForm.people === maxPeople
																	? "disabled"
																	: ""
															}`}
														>
															+
														</button>
													</div>
												</div>
												<p>
													<select
														id="fturn"
														name="turn"
														placeholder={t("Turno")}
														required
														className="p1 formel"
														defaultValue={initialForm.turn || ""}
													>
														<option value="" disabled>
															{t("Turno")}
														</option>
														<option value="brunch">Brunch</option>
														<option value="lunch">{t("Comidas")}</option>
														<option value="dinner">{t("Cenas")}</option>
													</select>
												</p>
												{peopleDisclaimer === "max" && (
													<p>
														<Trans>Para reservas de más de</Trans> {maxPeople}{" "}
														<Trans>
															personas contáctanos a través de nuestro{" "}
															<Link to="/reserva-grupos">
																apartado de grupos
															</Link>
														</Trans>
													</p>
												)}
												<p>
													<Trans ns="bookingAPI">
														Si necesitas trona o vienes con carrito, indica 1
														persona más en tu reserva.
													</Trans>
												</p>
												{(!initialForm.restaurant ||
													!initialForm.people ||
													!initialForm.turn) && (
													<p>
														*
														<Trans ns="bookingAPI">
															Completa todos los campos para poder continuar
														</Trans>
													</p>
												)}
											</form>

											{dataFetched &&
												!errorFetchingData &&
												initialForm.restaurant &&
												initialForm.people &&
												initialForm.turn && (
													<div
														className={`calendarContainer ${
															fakeLoader ? "loading" : ""
														}`}
													>
														<BookingCalendar
															allRestaurants={initialForm.restaurant === "all"}
															minDate={minDate}
															maxDate={maxDate}
															selectedDay={selectedDay}
															setSelectedDay={setSelectedDay}
															initialForm={initialForm}
															restaurantsAvailability={restaurantsAvailability}
															availableDates={availableDates}
															setAvailableDates={setAvailableDates}
															semiAvailableDates={semiAvailableDates}
															setSemiAvailableDates={setSemiAvailableDates}
															turns={turns}
														/>
														{selectedDay && <CalendarLegend />}
													</div>
												)}

											{errorFetchingData && (
												<p>
													<Trans ns="bookingAPI">ERROR CARGANDO DATOS</Trans>
													<br />
													<Trans ns="bookingAPI">
														Por favor inténtalo de nuevo en unos instantes. Si
														el problema persiste, contacta con nosotros.
													</Trans>
												</p>
											)}
										</div>

										{dataFetched &&
											!errorFetchingData &&
											initialForm.restaurant &&
											initialForm.people &&
											initialForm.turn && (
												<div id="zoneSelect">
													{!selectedDay && (
														<div>
															<p>
																<Trans ns="bookingAPI">
																	Selecciona una fecha para continuar con la
																	reserva
																</Trans>
															</p>
															<CalendarLegend />
														</div>
													)}
													{selectedDay && (
														<BookingHourZoneSelector
															restaurantsData={restaurantsData}
															initialForm={initialForm}
															selectedDay={selectedDay}
															availableDates={availableDates}
															turns={turns}
															restaurantForm={restaurantForm}
															setRestaurantForm={setRestaurantForm}
															setScreen={setScreen}
														/>
													)}
												</div>
											)}
									</div>
								),
								1: (
									<div className="lastStep">
										<button onClick={() => setScreen(0)} className="backButton">
											<Trans ns="bookingAPI">Volver</Trans>
										</button>

										<div>
											<BookingForm
												setReservResponse={setReservResponse}
												restaurantForm={restaurantForm}
												restaurantData={restaurantsData?.nodes.find(
													(restaurant) =>
														restaurant?.frontmatter?.coverManagerId ===
														restaurantForm?.restaurant
												)}
												personDetails={personDetails}
												setPersonDetails={setPersonDetails}
											/>
										</div>
									</div>
								),
								2: (
									<div className="confirmation">
										<h1>
											<Trans ns="bookingAPI">Reserva realizada con éxito</Trans>
										</h1>
										{/* <p>
										<Trans ns="bookingAPI">Tu id de reserva es </Trans>
										{reservResponse?.id_reserv}
									</p> */}

										<p>
											<Trans ns="bookingAPI">Los datos de tu reserva:</Trans>
										</p>
										<p className="bookingDetails">
											{personDetails.first_name} {personDetails.last_name} |{" "}
											{restaurantForm?.date} | {restaurantForm?.people}{" "}
											<Trans ns="bookingAPI">personas</Trans> |{" "}
											{restaurantForm?.time} | {restaurantForm?.zone?.name} |
											Begin{" "}
											{
												restaurantsData?.nodes.find(
													(restaurant) =>
														restaurant?.frontmatter?.coverManagerId ===
														restaurantForm?.restaurant
												)?.frontmatter?.title
											}
										</p>
										<p>
											<Trans ns="bookingAPI">
												Te hemos enviado todos los detalles a tu email
											</Trans>{" "}
											({personDetails.email})
										</p>
										<button
											onClick={() => {
												// reset the whole form
												setInitialForm(initialForm_initialState);
												setRestaurantForm(restaurantForm_initialState);
												setPersonDetails(personDetails_initialState);
												setScreen(0);
											}}
											aria-label={t("Hacer otra reserva")}
										>
											<h3>
												<Trans>Hacer otra reserva</Trans>
											</h3>
										</button>
									</div>
								),
							}[screen]
						}
					</div>
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
}
